import { render, staticRenderFns } from "./Assembly.vue?vue&type=template&id=4d6c8ba4&"
import script from "./Assembly.vue?vue&type=script&lang=ts&"
export * from "./Assembly.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ContentsBlocks: require('/home/ubuntu/FirstFocusAgents/CVL-NUC-AGENT-1/_work/33/a/NuxtApp/components/contents/Blocks.vue').default,BaseOverlay: require('/home/ubuntu/FirstFocusAgents/CVL-NUC-AGENT-1/_work/33/a/NuxtApp/components/base/Overlay.vue').default})
